var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("label", [_vm._v("URL")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formOctadesk.url,
                      expression: "formOctadesk.url",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.formOctadesk.url.$error },
                  attrs: { type: "text" },
                  domProps: { value: _vm.formOctadesk.url },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formOctadesk, "url", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-4" }, [
                _c("label", [_vm._v("Token")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formOctadesk.token,
                      expression: "formOctadesk.token",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.formOctadesk.token.$error },
                  attrs: { type: "text" },
                  domProps: { value: _vm.formOctadesk.token },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formOctadesk, "token", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("label", [_vm._v("WhatsApp")]),
                _c("input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["(##) #####-####", "(##) ####-####"],
                      expression: "['(##) #####-####', '(##) ####-####']",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formOctadesk.phone,
                      expression: "formOctadesk.phone",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.formOctadesk.phone.$error },
                  attrs: { type: "text" },
                  domProps: { value: _vm.formOctadesk.phone },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formOctadesk, "phone", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
        },
        [
          _c("div", { staticClass: "card-header mb-2" }, [
            _c("div", { staticClass: "form-row" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-md-2 col-4" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { 400: true },
                      },
                    ],
                    staticClass: "btn btn-primary btn-block",
                    attrs: { variant: "primary", type: "button" },
                    on: { click: _vm.repeateAgain },
                  },
                  [
                    _c("i", { staticClass: "bi bi-plus mr-25" }),
                    _c("span", [_vm._v("Novo")]),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "ul",
              {
                staticClass: "mt-1",
                staticStyle: { "list-style": "none", padding: "0px" },
              },
              _vm._l(_vm.formOctadesk.templates, function (template, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticStyle: {
                      padding: "15px 0px",
                      "border-bottom": "1px solid #f7f7f7",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" Nome "),
                          _c(
                            "svg",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value:
                                    "O Nome será exibido para o usuário na Seducar CRM.",
                                  expression:
                                    "'O Nome será exibido para o usuário na Seducar CRM.'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                width: "15",
                                height: "15",
                                fill: "none",
                              },
                            },
                            [
                              _c("circle", {
                                attrs: {
                                  cx: "12",
                                  cy: "12",
                                  r: "10",
                                  stroke: "currentColor",
                                  "stroke-width": "1.5",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5",
                                  stroke: "currentColor",
                                  "stroke-width": "1.5",
                                  "stroke-linecap": "round",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M11.992 17H12.001",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: template.name,
                              expression: "template.name",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.$v.formOctadesk.templates.$each[index].name
                                .$error,
                          },
                          attrs: { type: "text" },
                          domProps: { value: template.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(template, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-7" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(" ID do template "),
                          _c(
                            "svg",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value:
                                    "Este ID é encontrado no dashboard da Octadesk. Em Configurações > Modelos de mensagem.",
                                  expression:
                                    "'Este ID é encontrado no dashboard da Octadesk. Em Configurações > Modelos de mensagem.'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 24 24",
                                width: "15",
                                height: "15",
                                fill: "none",
                              },
                            },
                            [
                              _c("circle", {
                                attrs: {
                                  cx: "12",
                                  cy: "12",
                                  r: "10",
                                  stroke: "currentColor",
                                  "stroke-width": "1.5",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5",
                                  stroke: "currentColor",
                                  "stroke-width": "1.5",
                                  "stroke-linecap": "round",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M11.992 17H12.001",
                                  stroke: "currentColor",
                                  "stroke-width": "2",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: template.template_id,
                              expression: "template.template_id",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.$v.formOctadesk.templates.$each[index]
                                .template_id.$error,
                          },
                          attrs: { type: "text" },
                          domProps: { value: template.template_id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                template,
                                "template_id",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-1" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger btn-block",
                            staticStyle: { "margin-top": "22px" },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmDelete(template.uuid, index)
                              },
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 24 24",
                                  width: "16",
                                  height: "16",
                                  fill: "none",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5",
                                    stroke: "currentColor",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5",
                                    stroke: "currentColor",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M9.5 16.5L9.5 10.5",
                                    stroke: "currentColor",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "M14.5 16.5L14.5 10.5",
                                    stroke: "currentColor",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "form-row mt-1 justify-content-end" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { type: "submit", disabled: _vm.submited },
            },
            [
              _vm.submited
                ? _c(
                    "div",
                    [
                      _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                    ],
                    1
                  )
                : _c("span", [_vm._v("Salvar")]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header mb-2" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Octadesk "),
        _c(
          "small",
          { staticClass: "d-block", staticStyle: { "margin-top": "5px" } },
          [_vm._v(" Configure a integração com a Octadesk. ")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-10 col-8" }, [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v(" Templates "),
        _c(
          "small",
          { staticClass: "d-block", staticStyle: { "margin-top": "5px" } },
          [
            _vm._v(
              " Cadastre os templates a serem exibidos na plataforma Seducar CRM. "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }