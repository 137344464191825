<template>
  <form @submit.prevent="onSubmit">
    <div
      class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
    >
      <div class="card-header mb-2">
        <h4 class="mb-0">
          Octadesk
          <small class="d-block" style="margin-top: 5px;">
            Configure a integração com a Octadesk.
          </small>
        </h4>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-5">
            <label>URL</label>
            <input
              class="form-control"
              type="text"
              v-model="formOctadesk.url"
              :class="{ 'is-invalid': $v.formOctadesk.url.$error }"
            />
          </div>
          <div class="col-md-4">
            <label>Token</label>
            <input
              class="form-control"
              type="text"
              v-model="formOctadesk.token"
              :class="{ 'is-invalid': $v.formOctadesk.token.$error }"
            />
          </div>
          <div class="col-md-3">
            <label>WhatsApp</label>
            <input
              class="form-control"
              type="text"
               v-mask="['(##) #####-####', '(##) ####-####']"
              v-model="formOctadesk.phone"
              :class="{ 'is-invalid': $v.formOctadesk.phone.$error }"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
    >
      <div class="card-header mb-2">
        <div class="form-row">
          <div class="col-md-10 col-8">
            <h4 class="mb-0">
              Templates
              <small class="d-block" style="margin-top: 5px;">
                Cadastre os templates a serem exibidos na plataforma Seducar CRM.
              </small>
            </h4>
          </div>
          <div class="col-md-2 col-4">
            <button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
              class="btn btn-primary btn-block"
              type="button"
            >
              <i class="bi bi-plus mr-25"></i>
              <span>Novo</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ul class="mt-1" style="list-style: none; padding: 0px;">
          <li
            v-for="(template, index) in formOctadesk.templates"
            :key="index"
            style="padding: 15px 0px; border-bottom: 1px solid #f7f7f7; margin-bottom: 20px;"
          >
            <div class="form-row">
              <div class="col-md-4">
                <label for="">
                  Nome
                  <svg v-b-tooltip.hover.top="'O Nome será exibido para o usuário na Seducar CRM.'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" fill="none">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                    <path d="M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M11.992 17H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="template.name"
                  :class="{
                    'is-invalid': $v.formOctadesk.templates.$each[index].name.$error,
                  }"
                />
              </div>

              <div class="col-md-7">
                <label for="">
                  ID do template
                  <svg v-b-tooltip.hover.top="'Este ID é encontrado no dashboard da Octadesk. Em Configurações > Modelos de mensagem.'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15" fill="none">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                    <path d="M10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 9.39815 13.8837 9.76913 13.6831 10.0808C13.0854 11.0097 12 11.8954 12 13V13.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M11.992 17H12.001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="template.template_id"
                  :class="{
                    'is-invalid': $v.formOctadesk.templates.$each[index].template_id.$error,
                  }"
                />
              </div>

              <div class="col-md-1">
                <button style="margin-top: 22px;" type="button" class="btn btn-danger btn-block" @click="confirmDelete(template.uuid, index)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none">
                    <path d="M19.5 5.5L18.8803 15.5251C18.7219 18.0864 18.6428 19.3671 18.0008 20.2879C17.6833 20.7431 17.2747 21.1273 16.8007 21.416C15.8421 22 14.559 22 11.9927 22C9.42312 22 8.1383 22 7.17905 21.4149C6.7048 21.1257 6.296 20.7408 5.97868 20.2848C5.33688 19.3626 5.25945 18.0801 5.10461 15.5152L4.5 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M3 5.5H21M16.0557 5.5L15.3731 4.09173C14.9196 3.15626 14.6928 2.68852 14.3017 2.39681C14.215 2.3321 14.1231 2.27454 14.027 2.2247C13.5939 2 13.0741 2 12.0345 2C10.9688 2 10.436 2 9.99568 2.23412C9.8981 2.28601 9.80498 2.3459 9.71729 2.41317C9.32164 2.7167 9.10063 3.20155 8.65861 4.17126L8.05292 5.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M9.5 16.5L9.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M14.5 16.5L14.5 10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="form-row mt-1 justify-content-end">
      <div class="col-md-3">
        <button type="submit" class="btn btn-block btn-success" :disabled="submited">
          <div v-if="submited">
            <b-spinner small variant="light" />
          </div>
          <span v-else>Salvar</span>
        </button>
      </div>
    </div>  
  </form>
</template>

<script>
import {
  BTabs,
  BForm,
  BTab,
  BCard,
  BCol,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BTabs,
    BCol,
    BForm,
    BTab,
    BCard,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      submited: false,
      formOctadesk: {
        url: "",
        token: "",
        phone: "",
        templates: []
      },
      nextTodoId: 1,
      optionsApps: [],
    }
  },
  validations: {
    formOctadesk: {
      token: { required },
      url: { required },
      phone: { required },
      templates: {
        $each: {
          name: { required },
          template_id: { required }
        }
      }
    }
  },
  methods: {
    repeateAgain() {
      this.formOctadesk.templates.push({
        id: this.nextTodoId += this.nextTodoId,
        name: '',
        template_id: ''
      })
    },
    removeItem(index) {
      this.formOctadesk.templates.splice(index, 1)
    },
    confirmDelete(uuid, index) {
      this.$swal({
        title: "Tem certeza disso?",
        text: "Ao excluir, as oportunidades associadas ficarão sem etapa definida. Deseja continuar?",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then(async(result) => {
        if (result.value) {
          await this.$store.dispatch("Config/deleteSchoolOctadeskTemplate", uuid).then(() => {
            this.formOctadesk.templates.splice(index, 1);
          })
        }
      });
    },
    async fetchOctadesk() {
      try {
        await this.$store.dispatch("Config/fetchOctadesk").then((res) => {
          this.formOctadesk = res
        });

      } catch (error) {
        console.error("Error fetching opportunity steps:", error);
      }
    },
    hasValidationError(index, field) {
      const validation = this.$v.formOctadesk.$each[index][field];
      return validation && validation.$error;
    },
    async onSubmit() {
      this.$v.formOctadesk.$touch();
      if (!this.$v.formOctadesk.$error) {
        this.submited = true;
        try {
          await this.$store.dispatch("Config/updateSchoolOctadesk", this.formOctadesk).then(() => {
            this.notifyDefault("success");
          });       
        } catch (error) {
          console.error("Error updating steps:", error);
        } finally {
          this.submited = false;
        }
      }
    },
  },
  created() {
    this.fetchOctadesk();
  }
};
</script>